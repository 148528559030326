<template>
  <div>
    <c-table
      ref="tasktable"
      title="관련 교육 목록"
      :columns="grid.columns"
      :data="grid.data"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :expandAll="true"
      selection="multiple"
      rowKey="eduEducationId"
      checkDisableColumn="non"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&grid.data.length>0" label="삭제" icon="remove" @btnClicked="deleteTask" />
          <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addTask" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'riskEducation',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        vendorFlag: false,
      }),
    },
    task: {
      type: Object,
      default: () => ({
        insertUrl: '',
        deleteUrl: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "educationName",
            field: "educationName",
            label: "교육명",
            // style: 'width:250px',
            align: "left",
            sortable: true,
            type: "link",
          },
          {
            name: "educationCourseName",
            field: "educationCourseName",
            label: "교육과정",
            // style: 'width:200px',
            align: "left",
            sortable: true,
          },
          {
            name: "educationDate",
            field: "educationDate",
            label: "교육일",
            // style: 'width:200px',
            align: "center",
            sortable: true,
          },
          {
            name: "eduPlanTime",
            field: "eduPlanTime",
            label: "교육시간",
            // style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationKindCdLargeName",
            field: "educationKindCdLargeName",
            label: "교육종류1",
            // style: 'width:150px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationKindCdSmallName",
            field: "educationKindCdSmallName",
            label: "교육종류2",
            // style: 'width:150px',
            align: "center",
            sortable: true,
          },
          {
            name: "educationTypeName",
            field: "educationTypeName",
            label: "교육구분",
            align: "center",
            sortable: true,
          },
          {
            name: "educationMethodName",
            field: "educationMethodName",
            // style: 'width:90px',
            label: "교육방법",
            align: "center",
            sortable: true,
          },
          // {
          //   name: "educationPurpose",
          //   field: "educationPurpose",
          //   style: 'width:200px',
          //   label: "학습목적",
          //   align: "left",
          //   sortable: true,
          // },
          // {
          //   name: "relatedLawsName",
          //   field: "relatedLawsName",
          //   style: 'width:200px',
          //   label: "관련법규",
          //   align: "left",
          //   sortable: true,
          // },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        width: '',
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.edu.result.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId
      };
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.popupOptions.title = "교육계획 상세"; 
      this.popupOptions.param = {
        plantCd: row.plantCd ? row.plantCd : this.searchParam.plantCd,
        eduEducationId: row.eduEducationId ? row.eduEducationId : '',
        stepCd: row.documentStatusCd === 'ESC000001' ?  'ESC0000005' : 'ESC0000010',
      };
      this.popupOptions.target = () => import(`${"@/pages/sop/edu/result/educationResultDetail.vue"}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    deleteTask() {
      let selectData = this.$refs['tasktable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '제외하시겠습니까?',
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.task.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(this.$_.map(selectData, (item) => {
                return {
                  ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
                  eduEducationId: item.eduEducationId,
                }
              }))
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$refs['tasktable'].$refs['compo-table'].clearSelection();
              this.getList();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addTask() {
      this.popupOptions.title = '교육 검색'; 
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${"@/pages/sop/edu/result/educationPop.vue"}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeTaskPopup;
    },
    closeTaskPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let insertList = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid.data, { eduEducationId: item.eduEducationId }) === -1) {
            insertList.push({
              ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
              eduEducationId: item.eduEducationId,
            });
          }
        })
        this.$http.url = this.task.insertUrl;
        this.$http.type = 'POST';
        this.$http.param = insertList
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        },);
      }
    },
  }
};
</script>